package model.impl

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import model.Card
import model.ConsumerInformation
import model.DataRepository
import model.LoginResponse
import model.MerchantFullInformation
import model.NetworkController
import model.OrderPayment
import model.Payments
import navigation.Navigation
import navigation.NavigationState
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.parameter.parametersOf
import persistence.TokenPersistence
import receipt.Question
import receipt.QuestionAnswer
import receipt.Receipt
import ui.snackbars.SnackBarState

class DefaultDataRepository(
    private val scope: CoroutineScope,
    private val networkController: NetworkController,
    private val tokenPersistence: TokenPersistence,
) : DataRepository, KoinComponent {

    override val navigation by inject<Navigation> { parametersOf(scope, this) }

    override val merchantInformationMutableStateFlow: MutableStateFlow<MerchantFullInformation?> = MutableStateFlow(null)
    override val merchantInformationStateFlow = merchantInformationMutableStateFlow.asStateFlow()

    override val cardsMutableStateFlow: MutableStateFlow<List<Card?>?> = MutableStateFlow(null)
    override val cardsStateFlow = cardsMutableStateFlow.asStateFlow()

    override val consumerInformationMutableStateFlow: MutableStateFlow<ConsumerInformation?> = MutableStateFlow(null)
    override val consumerInformationStateFlow = consumerInformationMutableStateFlow.asStateFlow()

    override val paymentsMutableStateFlow: MutableStateFlow<DataRepository.PaymentState> =
        MutableStateFlow(DataRepository.PaymentState.Refreshing(emptyList()))
    override val paymentsStateFlow = paymentsMutableStateFlow.asStateFlow()

    override val eReceiptMutableStateFlow: MutableStateFlow<Receipt?> = MutableStateFlow(null)
    override val eReceiptStateFlow: StateFlow<Receipt?> = eReceiptMutableStateFlow.asStateFlow()

    override val orderQuestionMutableStateFlow: MutableStateFlow<Question?> = MutableStateFlow(null)
    override val orderQuestionStateFlow: StateFlow<Question?> = orderQuestionMutableStateFlow.asStateFlow()

    override val snackbarStateMutableStateFlow: MutableStateFlow<SnackBarState> = MutableStateFlow(SnackBarState.Nothing())
    override val snackbarStateStateFlow = snackbarStateMutableStateFlow.asStateFlow()

    override fun setSnackbarState(state: SnackBarState) {
        snackbarStateMutableStateFlow.value = state
    }

    override fun setMerchantInformation(merchantInformation: MerchantFullInformation?) {
        merchantInformationMutableStateFlow.value = merchantInformation
    }

    override fun setCards(cards: List<Card?>) {
        cardsMutableStateFlow.value = cards
    }

    override fun setConsumerInformation(consumerInformation: ConsumerInformation?) {
        consumerInformationMutableStateFlow.value = consumerInformation
    }

    override fun setPayments(payments: DataRepository.PaymentState) {
        paymentsMutableStateFlow.value = payments
    }

    override fun setEReceipt(eReceipt: Receipt?) {
        eReceiptMutableStateFlow.value = eReceipt
    }

    override fun setOrderQuestion(question: Question?) {
        orderQuestionMutableStateFlow.value = question
    }

    override fun getToken(): String? = tokenPersistence.getToken()

    override fun deleteToken() {
        tokenPersistence.deleteToken()
    }

    override fun setToken(token: String) {
        tokenPersistence.setToken(token)
    }

    override suspend fun setConsumerInformationFromToken(onSet: (isSet: Boolean) -> Unit) {
        val consumerInformation = networkController.getConsumerInformation()
        if (consumerInformation != null) {
            setConsumerInformation(consumerInformation)
            setCards(consumerInformationStateFlow.value?.cards ?: emptyList())
            onSet(true)
        } else {
            tokenPersistence.deleteToken()
            onSet(false)
        }
    }

    override fun loadPaymentsAsync(payments: List<OrderPayment>, consumerLoyaltyProgramGuid: String) {
        setPayments(DataRepository.PaymentState.Refreshing(payments))
        scope.launch {
            setPayments(
                DataRepository.PaymentState.Payments(
                    getMerchantInformationAsync(
                        consumerLoyaltyProgramGuid
                    ).orderPayment
                )
            )
        }
    }

    override fun loadEreceiptAsync(orderPaymentGuid: String) {
        setEReceipt(null)
        setOrderQuestion(null)
        scope.launch {
            val eReceipt = networkController.getEReceiptInformation(orderPaymentGuid)
            if (eReceipt.orderPaymentGuid.isNotBlank()) {
                setEReceipt(eReceipt)
                loadQuestionAsync(orderPaymentGuid)
            } else {
                snackbarStateMutableStateFlow.value = SnackBarState.InvalidEreceipt()
            }
        }
    }

    override fun loadQuestionAsync(orderPaymentGuid: String) {
        setOrderQuestion(null)
        scope.launch {
            val nextQuestion = networkController.getQuestions(orderPaymentGuid)
            if (nextQuestion.isNotEmpty()) {
                setOrderQuestion(nextQuestion.first())
            }
        }
    }

    override fun answerQuestion(orderPaymentGuid: String, questionId: Int, score: Int) {
        setOrderQuestion(null)
        scope.launch {
            networkController.answerQuestion(QuestionAnswer(orderPaymentGuid, questionId, score))
            loadQuestionAsync(orderPaymentGuid)
        }
    }

    override suspend fun authenticateGuid(guid: String): String {
        return networkController.authenticateGuid(guid)
    }

    override suspend fun getMerchantInformationAsync(consumerLoyaltyProgramGuid: String): Payments {
        return networkController.getMerchantInformation(consumerLoyaltyProgramGuid)
    }

    override suspend fun requestAuthenticationFromOrderPaymentGuid(guid: String) {
        return networkController.requestAuthenticationFromOrderPaymentGuid(guid)
    }

    override suspend fun submitPhoneNumber(phoneNumber: Long): LoginResponse {
        return networkController.submitPhoneNumber(phoneNumber)
    }

    override suspend fun bypassLogin(token: String) {
        setToken(token)
        setMerchantInformation(null)
        setConsumerInformationFromToken { isSet ->
            if (isSet) {
                navigate(NavigationState.ConsumerInformation())
            } else {
                navigate(NavigationState.Login())
            }
        }
    }

    override fun logOut() {
        deleteToken()
        navigate(NavigationState.Login())
    }

    override fun setMerchant(merchant: MerchantFullInformation) {
        val lastSelectedMerchant = merchantInformationStateFlow.value
        if (lastSelectedMerchant != merchant) {
            setMerchantInformation(merchant)
            loadPaymentsAsync(
                emptyList(),
                merchant.consumerLoyaltyProgramGuid!!
            )
        } else {
            loadPaymentsAsync(
                paymentsStateFlow.value.payments,
                merchantInformationStateFlow.value!!.consumerLoyaltyProgramGuid!!
            )
        }
        navigate(NavigationState.MerchantInformation(merchant.consumerLoyaltyProgramGuid!!))
    }

    override fun onTransactionClicked(orderPaymentGuid: String) {
        if (eReceiptStateFlow.value?.orderPaymentGuid != orderPaymentGuid) {
            loadEreceiptAsync(orderPaymentGuid)
        }
        navigate(NavigationState.EReceipt(orderPaymentGuid))
    }

    override val navigationStateFlow = navigation.navigationStateFlow
    override val backStackStateFlow = navigation.backStackStateFlow
    override fun navigate(navigationState: NavigationState) = navigation.navigate(navigationState)
    override fun popBackStack() = navigation.popBackStack()
    override fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit) = navigation.hashStateChangeEvent(newHash, locationListener)
    override suspend fun cancelEnrollment(consumerLoyaltyProgramGuid: String) = networkController.cancelEnrollment(consumerLoyaltyProgramGuid)
}