package model

import kotlinx.serialization.Serializable

@Serializable
data class MerchantFullInformation(
    val consumerLoyaltyProgramGuid: String? = "",
    val loyaltyProgramId: Int? = -1,
    val enrollMerchantId: Int? = -1,
    val enrollLocationId: Int? = -1,
    val pointBalanceCurrent: Double? = 0.0,
    val rewardBalanceCurrent: Double? = 0.0,
    val consumerId: Int? = -1,
    val merchantId: Int? = -1,
    val name: String? = "",
    val phoneNumber: Long? = -1,
    val address: String? = "",
    var points: Points? = null,
    val optInType: Int? = -1,
)