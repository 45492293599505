package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.selection.DisableSelection
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import model.ConsumerInformation
import model.MerchantFullInformation
import receipt.Question
import receipt.Receipt
import ui.Colors
import kotlin.reflect.KSuspendFunction1

val maxWidth = 300.dp

@Composable
fun EReceiptScreen(
    eReceiptStateFlow: StateFlow<Receipt?>,
    onCancelEnrollmentClicked: KSuspendFunction1<String, Unit>,
    onTermsOfServiceClicked: () -> Unit,
    onPrivacyPolicyClicked: () -> Unit,
    onManageAccountClicked: (String) -> Unit,
    isTokenAvailable: Boolean,
    questionStateFlow: StateFlow<Question?>,
    onScore: (String, Int, Int) -> Unit,
    merchantInformationStateFlow: StateFlow<MerchantFullInformation?>,
    consumerInformationStateFlow: StateFlow<ConsumerInformation?>,
) {
    val scrollState = rememberScrollState()
    val scope = rememberCoroutineScope()
    SelectionContainer {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .background(Colors.Default)
                .padding(top = 8.dp)
                .verticalScroll(scrollState),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {

            val _eReceipt by eReceiptStateFlow.collectAsState()
            val eReceipt = _eReceipt

            if (eReceipt != null) {
                val consumerInformation by consumerInformationStateFlow.collectAsState()
                val loggedInCustomerIdMatchesReceiptCustomerId = consumerInformation?.consumer?.consumerId != eReceipt.consumerId
                if (consumerInformation != null && loggedInCustomerIdMatchesReceiptCustomerId) {
                    DifferentCustomerBanner()
                    Spacer(
                        modifier = Modifier
                            .height(8.dp)
                            .widthIn(0.dp, maxWidth),
                    )
                }
                PerforatedEdge(
                    boxWidth = maxWidth
                )
                Divider(
                    modifier = Modifier
                        .height(32.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                LocationTitleBanner(eReceipt.locationDetails)
                ReceiptContent(eReceipt)
                Balance(
                    eReceipt.rewardBalanceMessage,
                    merchantInformationStateFlow,
                    loggedInCustomerIdMatchesReceiptCustomerId,
                )
                Divider(
                    modifier = Modifier
                        .height(16.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                eReceipt.transactionDetails.filter { it.key != "Order ID" && it.key != "Date" && it.key != "Time" }.let {
                    TransactionInformation(it)
                }
                Divider(
                    modifier = Modifier
                        .height(32.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                PerforatedEdge(
                    isTopPosition = false,
                    boxWidth = maxWidth
                )
                DisableSelection {
                    QuestionSection(
                        questionStateFlow = questionStateFlow,
                        onScore = { questionId, rating ->
                            onScore(eReceipt.orderPaymentGuid, questionId, rating)
                        },
                    )
                    Links(
                        isTokenAvailable = isTokenAvailable,
                        currentCustomerIdMatchesExistingCustomerId = loggedInCustomerIdMatchesReceiptCustomerId,
                        onCancelEnrollmentClicked = {
                            scope.launch {
                                onCancelEnrollmentClicked(eReceipt.consumerLoyaltyProgramGuid.orEmpty())
                            }
                        },
                        onTermsOfServiceClicked = onTermsOfServiceClicked,
                        onPrivacyPolicyClicked = onPrivacyPolicyClicked,
                    ) {
                        onManageAccountClicked(eReceipt.orderPaymentGuid)
                    }
                }
            } else {
                CircularProgressIndicator(modifier = Modifier.align(Alignment.CenterHorizontally))
            }
        }
    }
}